
























































































































import {Component, Vue } from 'vue-property-decorator';
import {Action } from 'vuex-class';
import {
  CaoComplianceDetailAction,
  CaoComplianceDetail as CaoComplianceDetailType,
  InlenerWerksoort,
  SalaryTableAction,
  WerksoortsAction, InlenerType,
} from '@/store/inleners/types';
import { SalaryTable } from '@/interfaces/salaryTable';
import {toReadableDateFormat} from '@/utilities/filters';
import SalaryTableDetail from '@/views/Inleners/SalaryTableDetail.vue';
import VButton from '@/components/VButton.vue';
import { GetInlenersCaoPdfAction } from '@/store/main/types';
import getInlenersCaoTemplate from '@/pdf-templates/inlenerscao';
import { ProfileImageAction } from '@/store/account/types';

@Component({
  filters: { toReadableDateFormat },
  components: { SalaryTableDetail, VButton },
})
export default class CaoComplianceDetail extends Vue {
  @Action('salaryTables', {namespace: 'inleners'}) private fetchSalaryTables!: SalaryTableAction;
  @Action('caoComplianceDetail', {namespace: 'inleners'}) private fetchCaoDetail!: CaoComplianceDetailAction;
  @Action('werksoorts', {namespace: 'inleners'}) private fetchWerksoorts!: WerksoortsAction;
  @Action('getInlenersCaoPdf', { namespace: 'main' })
  private getInlenersCaoPdf!: GetInlenersCaoPdfAction;
  @Action('profileImage', { namespace: 'account' }) private getProfileImage!: ProfileImageAction;

  private subscriptionType = InlenerType.SUBSCRIPTION;
  private loaded = false;
  private makingPdf = false;
  private imageSrc = '';
  private werksoorts!: InlenerWerksoort[];
  private complianceDetail!: CaoComplianceDetailType;
  private salaryTables!: SalaryTable[];
  private hourlyWages = false;

  private created() {
    this.getProfileImage().then((response) => {
      this.imageSrc = response;
    });
    const complianceId = this.$route.params.id;
    this.fetchCaoDetail(complianceId).then((complianceDetail) => {
      this.complianceDetail = complianceDetail;
      Promise.all([
        this.fetchSalaryTables(complianceDetail.cao.cao_id),
        this.fetchWerksoorts(complianceDetail.cao.cao_id),
      ]).then(([salaryTables, werksoorts]) => {
        this. salaryTables = salaryTables;
        this.werksoorts = werksoorts;
      })
      .finally(() => {
        this.loaded = true;
      });
    });
  }

  private makePdf() {
    if (!this.complianceDetail) { return; }
    this.makingPdf = true;
    const html = getInlenersCaoTemplate((this.$refs.details as any).innerHTML, this.imageSrc);
    this.getInlenersCaoPdf({ html, filename: this.complianceDetail.cao.readable_name_year })
    .then((link) => {
      link.click();
    })
    .finally(() => {
      this.makingPdf = false;
    });
  }
}

